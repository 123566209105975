<template>
  <b-overlay :show="isLoading">
    <h1>Send mail</h1>
    <b-card class="mb-3">
      <b-form-group label="User" :disabled="sendToAll">
        <!-- {{users}} -->
        <model-list-select
          v-model="selectedUser"
          :list="users"
          option-text="email"
          option-value="id"
          placeholder="Select user"
        ></model-list-select>
      </b-form-group>
      <b-form-group>
        <b-form-checkbox v-model="sendToAll">
          Send to everyone
        </b-form-checkbox>
      </b-form-group>
      <b-form-group label="Mail template">
        <model-list-select
          v-model="selectedMailTemplate"
          :list="mailTemplates"
          option-text="subject"
          option-value="id"
          placeholder="Choose template"
        ></model-list-select>
      </b-form-group>
      <b-button @click="sendMail">
        Send Mail
      </b-button>
    </b-card>
  </b-overlay>
</template>
<script>
import { ModelListSelect } from 'vue-search-select'

export default {
  name: "Notifications",
  components: {
    ModelListSelect
  },
  data() {
    return {
      users: [],
      mailTemplates: [],
      selectedUser: {},
      selectedMailTemplate: null,
      entity: "",
      sendToAll: false,
      isLoading: false,
      editorOption: {},
    };
  },
  methods: {
    async fetchUsers() {
      await this.$store.dispatch("Users/fetchAll");
    },
    async fetchMails() {
      await this.$store.dispatch("Mails/fetchAll");
    },
    async sendMail() {
      this.isLoading = true;
      let payload = {}
      if(this.sendToAll) {
        payload = {
          user_ids: this.users.map(user=>user.id),
          template_id: this.selectedMailTemplate,
          send_to_all: true
        }
      } else {
        payload = {
          user_ids: [this.selectedUser.id],
          template_id: this.selectedMailTemplate,
          send_to_all: false
        };
      }
      await this.$store.dispatch("Mails/sendMail", payload);
      this.isLoading = false;
    },
  },
  async created() {
    this.isLoading = true
    await this.fetchUsers();
    await this.fetchMails()
    this.users = this.$store.getters["Users/all"]?.data
    this.mailTemplates = this.$store.getters["Mails/all"]?.data;
    this.isLoading = false
  },
};
</script>
<style lang="scss"></style>
